// import React from "react";
// import { ToastContainer } from "react-toastify";

// import Identification from "./views/pages";
// import DimsMobile from "./views/pages/dimsmobile";
// import Riskcovry from "./views/pages/riskcovry";
// import AirtelPaymentBank from "./views/pages/apb";
// import MedicalMobile from "./views/pages/medicalmobile";

// const App = () => {
//   return (
//     <div>
//       <Identification />
//       {/* <AirtelPaymentBank /> */}
//       {/* <Riskcovry /> */}
//       {/* <DimsMobile /> */}
//       {/* <MedicalMobile /> */}
//       <ToastContainer position="top-right" />
//     </div>
//   );
// };
// export default App;

import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import React from "react";

import Apb from "./views/pages/apb";
import Identification from "./views/pages";
import ApbCertificate from "./views/pages/ApbCertificate";

import StatusPage from "./views/statuspage";

// import Riskcovry from "./views/pages/riskcovry";

// import Dimsmobile from "./views/pages/dimsmobile";
// import DmiFinance from "./views/pages/medicalmobile";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route exact path="/" element={<Identification />} /> */}

        {/* ****** Apb Page ****** */}
        <Route exact path="/" element={<Apb />} />
        <Route exact path="/apb-certificate" element={<ApbCertificate />} />

        {/* ****** Upload Successful Page ****** */}
        <Route exact path="/statusPage" element={<StatusPage />} />

        {/* ****** Riskcovry Page ****** */}
        {/* <Route exact path="/" element={<Riskcovry />} /> */}

        {/* ****** DMI Mobile Page ****** */}
        {/* <Route exact path="/" element={<Dimsmobile />} /> */}

        {/* ****** DMI Finance Page ****** */}
        {/* <Route exact path="/" element={<DmiFinance />} /> */}

        {/* <Route path="/payment-success" element={<PaymentSuccessPage />} /> */}

        {/* When we enter wrong route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
